@import url(https://fonts.googleapis.com/css?family=Unica+One&display=swap);
body {
  margin: 0;
  font-family: 'Unica One', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* email modal*/
.m-modal {
  font-family: 'Unica One', cursive !important;
}
.m-modal-content {
  padding: 0 !important;
  font-family: 'Unica One', cursive !important;
  border-radius: 0 !important;
  background-color: #E1E1DF !important;
  box-shadow: 1px 1px 19px rgba(255, 255, 255, .5), 1px 1px 19px rgba(0, 0, 0, .5) !important;
}

.m-title {
  background-color: #222629 !important;
  color: #E1E1DF !important;
  font-family: 'Unica One', cursive !important;
  font-size: 18px !important;
  padding: 10% 0 !important;
}

.m-by {
  display: none !important;
}

a {
  text-transform: uppercase !important;
}

/** CUBE */
 body {
	 background: #000000;
	 -webkit-user-select: none;
	    -moz-user-select: none;
	     -ms-user-select: none;
	         user-select: none;
	 color: #E1E1DF;
	 overflow: hidden;
}

.jumbotron.jumbotron-fluid {
  display: none;
}

.interactive-content {
  height: 90vh;
}

.jumbotron.jumbotron-fluid {
  height: 100%;
}

.name-logo {
  border-top: 1px solid #E1E1DF;
  border-bottom: 1px solid #E1E1DF;
  text-align: left;
  line-height: .9em;
  padding-left: 2%;
  width: 50%;
}

.light-switch {
  border: 1px solid #E1E1DF;
  width: 30%;
  text-align: center;
  padding: 5px;
  cursor: pointer;
  position: fixed;
  bottom: 10px;
  width: 4em;
  margin-bottom: 0;
}
.light-switch:hover {
  border-bottom: 3px solid #E1E1DF;
  border-right: 3px solid #E1E1DF;
}

span.next-handler {
  margin-right: auto;
  margin-left: 50%;
  cursor: pointer;
}
 .navbar, .footer{
   font-size: 20px;
   margin: 2em;
 }

 .footer {
   text-align: right;
   position: fixed;
   bottom: 0;
   right: 0;
 }

 .navbar a {
   color: #E1E1DF;
   text-decoration: none;
 }


 .navbar a:hover {
   text-shadow: 1px 1px 19px rgba(255, 255, 255, .5), 1px 1px 19px rgba(0, 0, 0, .5);
 }

 .wrapper {
	 display: flex;
	 align-items: center;
	 justify-content: center;
}
 .container:not(.text) {
	 position: fixed;
   right: 20%;
   bottom: 10%;
	 width: 10em;
	 height: 40em;
	 -webkit-transition: -webkit-perspective 2000ms ease;
	 transition: -webkit-perspective 2000ms ease;
	 transition: perspective 2000ms ease;
	 transition: perspective 2000ms ease, -webkit-perspective 2000ms ease;
}

.container.text {
  margin: 10%;
}

.container.text h1 {
  font-size: 90px;
  text-transform: uppercase;
}


.container.text p {
  font-size: 32px;
  text-transform: lowercase;
}

.slides-counter {
  display: flex;
  height: 2em;
  text-align: right;
  margin-top: 10%;
  position: fixed;
  bottom: 5%;
  font-size: 3em;
  font-family: 'Roboto';
  width: 100%;
  left: 47%;
}

.slides-counter > p.active {
  line-height: 1;
}

 .cube {
   font-size: 9px;
	 height: 100%;
	 width: 100%;
	 position: absolute;
	 -webkit-transform-style: preserve-3d;
	         transform-style: preserve-3d;
	 -webkit-transition: -webkit-transform 2000ms ease;
	 transition: -webkit-transform 2000ms ease;
	 transition: transform 2000ms ease;
	 transition: transform 2000ms ease, -webkit-transform 2000ms ease;
}
 .cube.animated {
	 -webkit-transition-duration: 2000ms;
	         transition-duration: 2000ms;
}
 .cube figure {
	 position: absolute;
	 margin: 0;
	 height: 40em;
	 width: 40em;
	 background: #fff;
	 display: block;
	 -webkit-transition: opacity 2000ms;
	 transition: opacity 2000ms;
}
 .cube figure:nth-of-type(1) {
	 background: transparent;
	 -webkit-transform: translateZ(150px);
	         transform: translateZ(150px);
}
 .cube figure:nth-of-type(4):before {
	 color: #E1E1DF;
	 font-size: 80px;
	 font-weight: 100;
	 text-align: center;
	 position: fixed;
	 left: -45%;
   text-shadow: 6px 6px 18px rgba(255, 255, 255, 0.4), 3px 3px 9px rgba(0, 0, 0, 0.9);
   border-top: 4px solid #E1E1DF;
   border-bottom: 4px solid #E1E1DF;
   line-height: 65px;
   padding-left: 5%;
   padding-right: 40%;
	 content: 'K|n3Sis';
}
 .cube figure:nth-of-type(2) {
   display: none;
	 background: #FB8122;
   border: 1px dashed #E1E2E2;
	 -webkit-transform: rotateX(190deg) translateZ(20em);
	         transform: rotateX(190deg) translateZ(20em);
}

 .cube figure:nth-of-type(3) {
   background: transparent;
	 -webkit-transform: rotateY(-45deg) translateZ(20em);
	         transform: rotateY(-45deg) translateZ(20em);
}
 .cube figure:nth-of-type(4) {
	 background: transparent;
	 -webkit-transform: rotateY(45deg) translateZ(20em);
	         transform: rotateY(45deg) translateZ(20em);
}
 .cube figure:nth-of-type(5) {
	 background: #ED3038;
	 -webkit-transform: rotateX(30deg) translateZ(20em);
	         transform: rotateX(30deg) translateZ(20em);
}
 .cube figure:nth-of-type(6) {
   background: #B30015;
	 -webkit-transform: rotateX(-30deg) translateZ(20em);
	         transform: rotateX(-30deg) translateZ(20em);
}


@media only screen and (max-width: 1366px) {
  .wrapper {
    opacity: .2;
  }

  .container:not(.text) {
    width: 20em;
    height: 30em;
 }
}

@media only screen and (max-width: 720px) {
   .wrapper {
     opacity: .2;
   }

   .jumbotrons {
     height: 100%;
     max-height: 100vh;
   }

   .container.text h1 {
     font-size: 48px;
     text-transform: uppercase;
   }

   .container.text p {
     font-size: 18px;
     text-transform: lowercase;
   }

   .name-logo {
     margin: 0;

   }

   .light-switch {
     margin-left: 0;
     bottom: 5px;
   }

   p {
     text-align: left;
   }

   .container:not(.text) {
    position: fixed;
    right: 20%;
    bottom: 10%;
    width: 5em;
    height: 30em;
    -webkit-transition: -webkit-perspective 2000ms ease;
    transition: -webkit-perspective 2000ms ease;
    transition: perspective 2000ms ease;
    transition: perspective 2000ms ease, -webkit-perspective 2000ms ease;
  }

  .slides-counter {
    font-size: 2em;
    left: 45%;
    bottom: 15%;
  }

  .footer {
    font-size: 10px;
    bottom: 15px;
  }
}

