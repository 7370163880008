@import url('https://fonts.googleapis.com/css?family=Unica+One&display=swap');
body {
  margin: 0;
  font-family: 'Unica One', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* email modal*/
.m-modal {
  font-family: 'Unica One', cursive !important;
}
.m-modal-content {
  padding: 0 !important;
  font-family: 'Unica One', cursive !important;
  border-radius: 0 !important;
  background-color: #E1E1DF !important;
  box-shadow: 1px 1px 19px rgba(255, 255, 255, .5), 1px 1px 19px rgba(0, 0, 0, .5) !important;
}

.m-title {
  background-color: #222629 !important;
  color: #E1E1DF !important;
  font-family: 'Unica One', cursive !important;
  font-size: 18px !important;
  padding: 10% 0 !important;
}

.m-by {
  display: none !important;
}

a {
  text-transform: uppercase !important;
}
